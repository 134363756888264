import { DataGrid, GridColumns } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AuthProviderInfoDto,
  AuthRoleType,
  refetchAdminQueryAllUsersQuery,
  refetchAdminQueryOneUserQuery,
  useAdminQueryAllUsersQuery,
  useAdminUpdateOneUserMutation,
  UserUpdateInputDto,
} from "../../types/graphql";
import { useMemo } from "react";
import GoogleIcon from "@mui/icons-material/Google";
import PasswordIcon from "@mui/icons-material/Password";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router";
import { usePrompt } from "@hungphongbk/vth-sdk";
import { generate } from "generate-password";

function getIcon(provider: AuthProviderInfoDto) {
  switch (provider.providerId) {
    case "google.com":
      return <GoogleIcon />;
    case "password":
      return <PasswordIcon />;
    default:
      return null;
  }
}

type UserListProps = {};
export default function UserList(props: UserListProps): JSX.Element {
  const navigate = useNavigate();
  const { data, loading: fetching } = useAdminQueryAllUsersQuery();
  const [changeRole, { loading: changing }] = useAdminUpdateOneUserMutation({});

  const loading = fetching || changing;

  const prompt = usePrompt();

  const handleSwitchRole =
    (uid: string, oldRole: AuthRoleType) => async (e, newRole) => {
      if (oldRole === newRole) return;
      const input: UserUpdateInputDto = {};
      if (newRole === AuthRoleType.Admin) {
        const agree = await prompt({
          title: "Xác nhận Admin mới",
          message:
            "Bằng việc đồng ý, hệ thống sẽ gửi email cùng với mật khẩu được tạo ngẫu nhiên đến người dùng, cho phép người dùng đăng nhập vào trang này (admin.showcase.vaithuhay.com). Bạn có đồng ý?",
          okLabel: "Có",
          cancelLabel: "Không",
        });
        if (agree.status === "cancel") return;

        const exist = data!.getAllUsers.find((s) => s.uid === uid)!;

        input.role = newRole;
        input.password = generate({
          length: 10,
          numbers: true,
        });
      }
      await changeRole({
        variables: { uid, input },
        refetchQueries: [
          refetchAdminQueryOneUserQuery({ uid }),
          refetchAdminQueryAllUsersQuery(),
        ],
      });
    };

  const columns: GridColumns = [
    { field: "email", flex: 2 },
    {
      field: "providedData",
      width: 150,
      headerName: "Phương thức đăng nhập",
      renderCell({ value }) {
        return (
          <Stack direction={"row"} gap={1}>
            {(value as AuthProviderInfoDto[]).map((provider) => (
              <Tooltip title={provider.providerId} key={provider.providerId}>
                <Avatar sx={{ width: 32, height: 32 }}>
                  {getIcon(provider)}
                </Avatar>
              </Tooltip>
            ))}
          </Stack>
        );
      },
    },
    {
      headerName: "Quyền",
      field: "role",
      width: 250,
      renderCell({ id, value, getValue }) {
        if (value === AuthRoleType.Superadmin)
          return (
            <Typography sx={{ fontWeight: 700, color: "#444" }}>
              SUPERADMIN
              <Typography component={"span"} sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
          );
        return (
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={handleSwitchRole(id as string, value as AuthRoleType)}
            aria-label="text alignment"
            size={"small"}
          >
            {[AuthRoleType.User, AuthRoleType.Investor, AuthRoleType.Admin].map(
              (role) => (
                <ToggleButton value={role} key={role}>
                  {role}
                </ToggleButton>
              )
            )}
          </ToggleButtonGroup>
        );
      },
    },
  ];

  const allUsers = useMemo(() => {
    if (!data?.getAllUsers) return [];
    return data.getAllUsers.map((u) => ({
      id: u.uid,
      ...u,
    }));
  }, [data]);

  return (
    <Box>
      <Stack direction={"row"} sx={{ mb: 1 }}>
        <Button
          startIcon={<AddModeratorIcon />}
          variant={"contained"}
          onClick={() => navigate("add-admin")}
        >
          Thêm admin
        </Button>
      </Stack>
      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={allUsers}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10]}
          checkboxSelection
          disableSelectionOnClick
          autoHeight
          rowHeight={60}
          loading={loading}
        />
      </Box>
      <Outlet />
    </Box>
  );
}
