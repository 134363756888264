import PageTitle from "../../components/PageTitle";
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColumns,
} from "@mui/x-data-grid";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import {
  PublishStatus,
  refetchShowcaseItemQuery,
  refetchShowcasesQuery,
  ShowcaseFilter,
  ShowcaseSort,
  ShowcaseSortFields,
  ShowcaseStatus,
  SortDirection,
  useDeleteShowcaseMutation,
  usePublishShowcaseMutation,
  useShowcasesQuery,
} from "../../types/graphql";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import PublishIcon from "@mui/icons-material/Publish";
import Thumbnail from "./components/thumbnail";
import { MediaDto } from "@hungphongbk/vth-sdk";
import LinkTypography from "../../components/LinkTypography";
import { useToggleShowcaseFeaturedDocument } from "./apis";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import CommentIcon from "@mui/icons-material/Comment";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShowcaseManagementFilter from "./components/showcase-management-filter";
import { useRelayCursorPagination } from "../../utils/use-relay-cursor-pagination";
import { QuickSearchToolbar } from "../../components/quick-search-toolbar";
import { throttle } from "lodash";
import ShowcaseStatusChip from "../../components/showcase-status-chip";

export default function ShowcaseManagement(): JSX.Element {
  const navigate = useNavigate();

  // pagination section
  const {
    page,
    size,
    after,
    handlePageChange,
    handlePageSizeChange,
    setPageInfo,
  } = useRelayCursorPagination();

  const { enqueueSnackbar } = useSnackbar(),
    [filter, setFilter] = useState<ShowcaseFilter>({
      publishStatus: { eq: PublishStatus.Published },
    }),
    throttleSetFilter = useMemo(() => throttle(setFilter, 150), []);

  const [sorting, setSorting] = useState<ShowcaseSort[]>([
    { direction: SortDirection.Desc, field: ShowcaseSortFields.IsFeatured },
    { direction: SortDirection.Desc, field: ShowcaseSortFields.UpdatedAt },
  ]);
  let { data, loading: fetching } = useShowcasesQuery({
    variables: {
      size,
      filter,
      after,
      sorting,
    },
  });
  useEffect(() => {
    if (data?.showcases.pageInfo) setPageInfo(data.showcases.pageInfo);
  }, [data, setPageInfo]);

  const [publish, { loading: publishing }] = usePublishShowcaseMutation({
    refetchQueries: [
      refetchShowcasesQuery({
        size,
        filter,
      }),
    ],
  });

  const [deleteShowcase, { loading: deleting }] = useDeleteShowcaseMutation({
    refetchQueries: [
      refetchShowcasesQuery({
        size,
        filter,
      }),
    ],
  });

  const [toggleFeature] = useToggleShowcaseFeaturedDocument();

  const loading = fetching || publishing || deleting;
  const columns: GridColumns = [
    {
      field: "isFeatured",
      headerName: "",
      width: 50,
      filterable: false,
      renderCell: ({ value, getValue, id }) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() =>
            toggleFeature({
              variables: {
                slug: getValue(id, "slug"),
                value: !(value as boolean),
              },
              refetchQueries: [
                refetchShowcaseItemQuery({
                  slug: getValue(id, "slug") as string,
                }),
              ],
            })
          }
        >
          {(value as boolean) ? (
            <StarIcon sx={{ color: "primary.main" }} />
          ) : (
            <StarOutlineIcon sx={{ color: "#888" }} />
          )}
        </Box>
      ),
      sortable: false,
    },
    {
      field: "name",
      headerName: "Tên Showcase",
      flex: 3,
      renderCell: ({ value, getValue, id }) => (
        <Box sx={{ display: "flex", width: "100%" }}>
          <Thumbnail
            sx={{ flexBasis: "60px", flexGrow: 0, flexShrink: 0 }}
            src={getValue(id, "image") as unknown as MediaDto}
          />
          <Box
            sx={{
              "& .MuiTypography-root": {
                minHeight: "unset",
                maxHeight: "unset",
                display: "block",
              },
            }}
          >
            <LinkTypography
              to={getValue(id, "slug") as string}
              sx={{ fontWeight: 700, whiteSpace: "normal", fontSize: ".95em" }}
            >
              {value}
            </LinkTypography>
            <Box>
              <ShowcaseStatusChip
                value={getValue(id, "status") as ShowcaseStatus}
              />
              {getValue(id, "publishStatus") === PublishStatus.Draft && (
                <Chip
                  label="DRAFT"
                  size="small"
                  sx={{
                    bgcolor: "rgba(0,0,0,.65)",
                    color: "#FFBF00",
                    fontWeight: 400,
                    ml: 0.7,
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      ),
      sortable: false,
    },
    {
      field: "commentCount",
      headerName: "",
      width: 100,
      sortable: false,
      renderCell: ({ getValue, id }) => (
        <Box
          sx={{
            "& .MuiBadge-badge": { color: "white" },
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gridGap: "16px",
            color: "#888",
          }}
        >
          {/* bắt sự kiện thôg báo chi tiết */}
          <IconButton
            sx={{
              margin: "0",
              padding: "0",
            }}
            onClick={() => {
              if (getValue(id, "commentCount")) {
                handleClickCMT(getValue(id, "comments"));
              }
            }}
          >
            <Badge
              badgeContent={getValue(id, "commentCount")}
              color={"primary"}
            >
              <CommentIcon />
            </Badge>
          </IconButton>

          <IconButton
            sx={{
              margin: "0",
              padding: "0",
            }}
            onClick={() => {
              if (getValue(id, "preorderCount")) {
                handleClickCart(getValue(id, "preorders"));
              }
            }}
          >
            <Badge
              badgeContent={getValue(id, "preorderCount")}
              color={"primary"}
            >
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </Box>
      ),
    },
    {
      field: "author",
      headerName: "Người đăng",
      flex: 1,
      sortable: false,
      valueGetter: (p: any) => p.value.name,
    },
    {
      field: "updatedAt",
      headerName: "Chỉnh sửa",
      flex: 1,
      type: "dateTime",
      valueGetter: (p: any) => new Date(p.value),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Tác vụ",
      width: 150,
      getActions: ({ getValue, id }) =>
        [
          <GridActionsCellItem
            key={"__edit"}
            icon={<EditIcon />}
            label={"Edit"}
            onClick={() => navigate(`${getValue(id, "slug") as string}`)}
          />,
          <GridActionsCellItem
            key={"__delete"}
            icon={<DeleteOutlineIcon />}
            label={"Delete"}
            onClick={() => handleDeleteShowcase(getValue(id, "slug") as string)}
          />,
          // @ts-ignore
          getValue(id, "publishStatus") === PublishStatus.Draft && (
            <GridActionsCellItem
              key={"__publish"}
              icon={<PublishIcon />}
              label={"Publish"}
              onClick={async () => {
                await publish({
                  variables: {
                    slug: getValue(id, "slug") as string,
                    publishStatus: PublishStatus.Published,
                  },
                });
                enqueueSnackbar("Publish showcase thành công", {
                  variant: "success",
                });
              }}
            />
          ),
        ].filter(
          (v): v is ReactElement<GridActionsCellItemProps> => v !== false
        ),
    },
  ];

  const handleDeleteShowcase = useCallback(
    async (slug: string) => {
      await deleteShowcase({ variables: { slug } });
      enqueueSnackbar("Xoá showcase thành công", { variant: "success" });
    },
    [enqueueSnackbar, deleteShowcase]
  );

  // danh sach cart

  // console.log(preOrderList)

  // view cao nhất
  // const topViewShowcase = useTopviewQuery()
  // console.log(topViewShowcase)
  // su kien view cart
  const [preorderList, setPreorderList] = useState<any>(null);
  const [showPreorder, setShowPreorder] = useState<boolean>(false);

  const handleClickCart = (value) => {
    setPreorderList(value);
    //hien popup
    setShowPreorder(true);
  };
  const handleCloseCart = () => {
    setShowPreorder(false);
  };

  // su kien comment
  const [commentList, setCommentList] = useState<any>(null);
  const [showComment, setShowComment] = useState<boolean>(false);

  const [sortView, setSortView] = useState<boolean>(false);
  const [sortPreorder, setSortPreorder] = useState<boolean>(false);

  const handleClickCMT = (value) => {
    setCommentList(value);
    setShowComment(true);
  };
  const handleCloseCMT = () => {
    setShowComment(false);
  };
  // su kien top view

  const handleClickView = () => {
    if (sortView) {
      setSortView(false);
      setSortPreorder(false);
      setSorting([
        { direction: SortDirection.Desc, field: ShowcaseSortFields.IsFeatured },
        { direction: SortDirection.Desc, field: ShowcaseSortFields.UpdatedAt },
      ]);
    } else {
      setSortView(true);
      setSortPreorder(false);
      setSorting([
        { direction: SortDirection.Desc, field: ShowcaseSortFields.ViewCount },
      ]);
    }
  };

  const handleClickPreOrder = () => {
    if (sortPreorder) {
      setSortPreorder(false);
      setSortView(false);

      setSorting([
        { direction: SortDirection.Desc, field: ShowcaseSortFields.IsFeatured },
        { direction: SortDirection.Desc, field: ShowcaseSortFields.UpdatedAt },
      ]);
    } else {
      setSortPreorder(true);
      setSortView(false);

      setSorting([
        {
          direction: SortDirection.Desc,
          field: ShowcaseSortFields.PreorderCount,
        },
      ]);
    }
  };

  console.log(sortView);
  return (
    <>
      <PageTitle>Quản lý Showcases</PageTitle>
      <Stack sx={{ mb: 3 }} direction={"row"} gap={2}>
        <Button
          variant={"contained"}
          component={RouterLink}
          to={"/showcases/add"}
          size={"small"}
        >
          Thêm showcase mới
        </Button>
        <Button variant={"contained"} size={"small"} onClick={handleClickView}>
          Xem nhiều nhất
        </Button>
        <Button
          variant={"contained"}
          size={"small"}
          onClick={handleClickPreOrder}
        >
          Đặt trước nhiều nhất
        </Button>
        <ShowcaseManagementFilter value={filter} onChange={setFilter} />
      </Stack>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={data ? data.showcases.edges.map((s) => s.node) : []}
          rowCount={data?.showcases.totalCount ?? 0}
          columns={columns}
          rowsPerPageOptions={[5, 10]}
          autoHeight
          // checkboxSelection
          disableSelectionOnClick
          disableVirtualization
          rowHeight={90}
          loading={loading}
          components={{
            Toolbar: QuickSearchToolbar,
          }}
          componentsProps={{
            toolbar: {
              placeholder: "Nhập tên showcase để tìm...",
              onChange: (e) =>
                throttleSetFilter({
                  ...filter,
                  name: { iLike: `%${e.target.value}%` },
                }),
            },
          }}
          paginationMode={"server"}
          page={page}
          pageSize={size}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          disableColumnFilter
          disableColumnMenu
        />
      </Box>
      <Outlet />
      {/* popover cart  */}
      <Popover
        // id={pupopId}
        open={showPreorder}
        onClose={handleCloseCart}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 4 }}>
          {preorderList ? (
            <>
              {preorderList &&
                preorderList.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Avatar style={{ margin: "20px 15px 0 0" }}>
                      {item?.author?.name.slice(0, 1)}
                    </Avatar>
                    <ul style={{ listStyle: "none", padding: "0 0 10px" }}>
                      <li>{item?.author?.name}</li>
                      <li>{item?.author?.email}</li>
                    </ul>
                  </div>
                ))}
            </>
          ) : (
            <></>
          )}
        </Typography>
      </Popover>
      {/* popover commnent  */}
      <Popover
        // id={pupopId}
        open={showComment}
        onClose={handleCloseCMT}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 4 }}>
          {commentList ? (
            <>
              {commentList &&
                commentList.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Avatar style={{ margin: "20px 15px 0 0" }}>
                      {item?.author?.name.slice(0, 1)}
                    </Avatar>
                    <ul style={{ listStyle: "none", padding: "0 0 10px" }}>
                      <li>{item?.author?.name}</li>
                      <li>{item?.content}</li>
                    </ul>
                  </div>
                ))}
            </>
          ) : (
            <></>
          )}
        </Typography>
      </Popover>
    </>
  );
}
