import { useCallback, useMemo, useState } from "react";
import { GridSortModel } from "@mui/x-data-grid";

export const useGqlGridSortModel = <T = any>(
  defaultSortModel: GridSortModel = []
) => {
  const [queryOptions, setQueryOptions] =
    useState<GridSortModel>(defaultSortModel);

  const sort = useMemo(() => {
    return queryOptions.map((q) => ({
      direction: q.sort?.toUpperCase(),
      field: q.field,
    })) as unknown as T;
  }, [queryOptions]);

  const handleSortModelChange = useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions([...sortModel]);
  }, []);

  return { sort, handleSortModelChange };
};
