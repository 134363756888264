import { PropsWithChildren } from "react";
import styled from "styled-email-components";
import { mailCssBase, MailTable } from "./css";
import MailHeaderNew from "./MailHeaderNew";

const Div = styled.div`
    ${mailCssBase}
    margin: 0;
  `,
  Table = styled(MailTable)`
    border-spacing: 0;
    border-collapse: collapse;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  `,
  Tr = styled.tr`
    ${mailCssBase}
  `,
  Td = styled.td`
    ${mailCssBase}
  `,
  Center = styled.center`
    ${mailCssBase}
  `;

export default function MailWrapperNew(
  props: PropsWithChildren<{ logo: any }>
): JSX.Element {
  return (
    <Div>
      <Table>
        <tbody>
          <Tr>
            <Td>
              <Center>
                <MailHeaderNew logo={props.logo} />
                {props.children}
              </Center>
            </Td>
          </Tr>
        </tbody>
      </Table>
    </Div>
  );
}
MailWrapperNew.displayName = "MailWrapperNew";
// MailWrapper.components = [Div, Table, Tr, Td, Center];
