import * as yup from "yup";
import { ShowcaseStatus } from "../../types/graphql";

const yupStr = () => yup.string().required(),
  yupNum = yup.number().required(),
  yupPriceInput = yup.object({
    regular: yupNum,
    pioneer: yupNum.max(
      yup.ref("regular"),
      `'Tiên Phong' phải nhỏ hơn 'Thường'`
    ),
    preorder: yupNum.max(
      yup.ref("regular"),
      `'Đặt Trước' phải nhỏ hơn 'Thường'`
    ),
    promo: yupNum.max(yup.ref("regular"), `'Khuyến Mãi' phải nhỏ hơn 'Thường'`),
  });

export const showcaseAddValidation = yup.object({
  description: yupStr(),
  expectedQuantity: yup.mixed().when("status", {
    is: ShowcaseStatus.Coming,
    then: yupPriceInput.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  expectedSaleAt: yup.date().when("status", {
    is: ShowcaseStatus.Coming,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  expectedSaleEndAt: yup.date().when("status", {
    is: ShowcaseStatus.Coming,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  expectedSalePrice: yupPriceInput.required(),
  inventory: yup.mixed().when("status", {
    is: ShowcaseStatus.Coming,
    then: yup
      .object({
        adCostRate: yupNum,
        capitalizationRate: yupNum,
        expectedGrowthRate: yupNum,
        operatingCostRate: yupNum,
        revolvingInterval: yupNum,
      })
      .required(),
    otherwise: (schema) => schema.nullable(),
  }),
});
