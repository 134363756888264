import { getToken } from "../service/firebase-auth";
import { useEffect, useState } from "react";
import { useSubscribeFcmMutation } from "../types/graphql";

type PushNotificationProps = {};
export default function PushNotification(
  props: PushNotificationProps
): JSX.Element {
  const [isTokenFound, setTokenFound] = useState(false);
  const [subscribe] = useSubscribeFcmMutation();

  console.log("Token found", isTokenFound);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        console.log("Token is", data);
        await subscribe({ variables: { token: data, topic: ["admin"] } });
        console.log("Successfully subscribed to admin topic");
      }
      return data;
    }

    // noinspection JSIgnoredPromiseFromCall
    tokenFunc();
  }, [setTokenFound, subscribe]);

  return <></>;
}
