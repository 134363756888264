import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useExitDialog } from "../../utils/router";
import { useParams } from "react-router";
import {
  refetchBrandDetailQuery,
  useBrandDetailQuery,
  useUpdateBrandMutation,
} from "../../types/graphql";
import { useForm } from "react-hook-form";
import { FormInput, SvgImageUploader } from "@hungphongbk/vth-sdk";
import { PlusIcon } from "../../assets/icons";
import TextField from "../../components/TextField";
import { useEffect } from "react";
import { omit } from "lodash";

type BrandDetailProps = {};
export default function BrandDetail(props: BrandDetailProps): JSX.Element {
  const { id } = useParams<"id">();
  const exit = useExitDialog();

  const { data } = useBrandDetailQuery({
      variables: { id: id! },
    }),
    [updateBrand] = useUpdateBrandMutation({
      refetchQueries: [refetchBrandDetailQuery({ id: id! })],
    });

  const form = useForm({
      defaultValues: data?.brandDto,
    }),
    { control, reset, handleSubmit } = form;

  useEffect(() => {
    reset(omit(data?.brandDto, ["__typename", "id", "slug"]));
  }, [data, reset]);

  const onSubmit = async (values: any) => {
    await updateBrand({ variables: { id: id!, update: values } });
    exit();
  };

  return (
    <Dialog open={true} maxWidth={"md"} fullWidth onClose={exit}>
      <DialogTitle>Chỉnh sửa thương hiệu "{data?.brandDto?.name}"</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "180px 1fr",
              gridTemplateAreas: '"ava name" "ava slogan" "ava description"',
              gridGap: "16px",
              mt: 2,
            }}
          >
            <FormInput
              name={"logo"}
              control={control}
              component={SvgImageUploader}
              sx={{ gridArea: "ava" }}
            >
              <Stack
                direction={"column"}
                alignItems={"center"}
                id={"brand-logo-upload"}
              >
                <PlusIcon
                  sx={{ color: "black", height: 36, width: 36, mb: 0.5 }}
                />
                <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                  Logo thương hiệu
                </Typography>
                <Typography>SVG only</Typography>
              </Stack>
            </FormInput>
            <FormInput
              name={"name"}
              control={control}
              sx={{ gridArea: "name" }}
              component={TextField}
              label={"Tên thương hiệu"}
              required
              fullWidth
            />
            <FormInput
              name={"subtitle"}
              control={control}
              sx={{ gridArea: "slogan" }}
              component={TextField}
              label={"Slogan thương hiệu"}
              required
              fullWidth
            />
            <FormInput
              name={"description"}
              control={control}
              component={TextField}
              label={"Chú thích thương hiệu"}
              fullWidth
              multiline
              minRows={4}
              sx={{ gridArea: "description" }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={exit}>Bỏ qua</Button>
        <Button onClick={handleSubmit(onSubmit)}>Lưu lại</Button>
      </DialogActions>
    </Dialog>
  );
}
