import { ShowcaseStatus } from "../types/graphql";
import { Chip } from "@mui/material";
import {
  usingShowcaseStatusColor,
  usingShowcaseStatusLabel,
} from "../utils/colors";

type ShowcaseStatusChipProps = {
  value: ShowcaseStatus;
};
export default function ShowcaseStatusChip({
  value,
}: ShowcaseStatusChipProps): JSX.Element {
  return (
    <Chip
      label={usingShowcaseStatusLabel(value)}
      size={"small"}
      sx={{
        bgcolor: "rgba(0,0,0,.65)",
        color: usingShowcaseStatusColor(value),
        fontSize: ".9em",
      }}
    />
  );
}
