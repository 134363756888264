import { Control } from "react-hook-form";
import {
  PublishStatus,
  ShowcaseCreateInputDto,
  ShowcaseStatus,
  ShowcaseUpdateInputDto,
  useGetAllAdminUsersQuery,
} from "../../../types/graphql";
import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Typography,
} from "@mui/material";
import { FormInput } from "@hungphongbk/vth-sdk";
import TextField from "../../../components/TextField";
import { LoadingButton } from "@mui/lab";
import PublishIcon from "@mui/icons-material/Publish";
import PageCard from "../../../components/PageCard";

type ShowcaseAddPublisherProps<T, T2> = {
  isSubmitting: boolean;
  onSubmit: any;
} & (
  | {
      mode: "add";
      control: Control<T>;
    }
  | {
      mode: "edit";
      control: Control<T2>;
    }
);
export default function ShowcaseAddPublisher<
  T = ShowcaseCreateInputDto,
  T2 = ShowcaseUpdateInputDto
>(props: ShowcaseAddPublisherProps<T, T2>): JSX.Element;
export default function ShowcaseAddPublisher(
  props: ShowcaseAddPublisherProps<
    ShowcaseCreateInputDto,
    ShowcaseUpdateInputDto
  >
): JSX.Element {
  const { control, isSubmitting, onSubmit, mode } = props;
  const { data } = useGetAllAdminUsersQuery();
  return (
    <PageCard>
      <CardHeader title={"Đăng tải"} />
      <CardContent>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            gridGap: "12px",
          }}
        >
          {props.mode === "edit" && (
            <>
              <Typography color={"text.secondary"}>Tác giả</Typography>
              <FormInput
                id={"showcase-author"}
                name={"authorUid"}
                component={TextField}
                control={props.control}
                select
                size={"small"}
              >
                {data?.getAllUsers.map((user) => (
                  <MenuItem key={user.uid} value={user.uid}>
                    {user.name}
                  </MenuItem>
                ))}
              </FormInput>
            </>
          )}
          <Typography color={"text.secondary"}>Trạng thái Showcase</Typography>
          <FormInput
            id={"showcase-status"}
            name={"status"}
            component={TextField}
            control={props.control as Control<ShowcaseCreateInputDto>}
            select
            size={"small"}
          >
            <MenuItem value={ShowcaseStatus.Showcase}>Showcase</MenuItem>
            <MenuItem value={ShowcaseStatus.Idea}>Idea</MenuItem>
            <MenuItem value={ShowcaseStatus.Coming}>Coming Soon</MenuItem>
          </FormInput>
          <Typography color={"text.secondary"}>Trạng thái đăng</Typography>
          <FormInput
            id={"showcase-publish-status"}
            name={"publishStatus"}
            component={TextField}
            control={control as Control<ShowcaseCreateInputDto>}
            select
            size={"small"}
          >
            <MenuItem value={PublishStatus.Draft}>Nháp</MenuItem>
            <MenuItem value={PublishStatus.Published}>Công khai</MenuItem>
          </FormInput>
        </Box>
      </CardContent>
      <CardActions>
        <LoadingButton
          startIcon={<PublishIcon />}
          loading={isSubmitting}
          onClick={onSubmit}
        >
          <Typography
            component={"span"}
            sx={{ lineHeight: 0, fontWeight: 700 }}
          >
            Đăng tải
          </Typography>
        </LoadingButton>
      </CardActions>
    </PageCard>
  );
}
