import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useExitDialog } from "../../utils/router";
import {
  AuthRoleType,
  refetchAdminQueryAllUsersQuery,
  useAdminCreateNewUserMutation,
  UserCreateInputDto,
} from "../../types/graphql";
import { useForm } from "react-hook-form";
import { FormInput } from "@hungphongbk/vth-sdk";
import { useCallback } from "react";
import { LoadingButton } from "@mui/lab";

type AddUserProps = {};
export default function AddUser(props: AddUserProps): JSX.Element {
  const exit = useExitDialog();

  const form = useForm<UserCreateInputDto>({
      defaultValues: {
        role: AuthRoleType.Admin,
      },
    }),
    { control, handleSubmit, formState } = form,
    [createNewUser] = useAdminCreateNewUserMutation({
      refetchQueries: [refetchAdminQueryAllUsersQuery()],
    });

  const onSubmit = useCallback(
    async (values: UserCreateInputDto) => {
      const { data } = await createNewUser({ variables: { input: values } });
      // const uid = data!.createOneUserDto.uid;
      // await refetchNewUser({ variables: { uid } });
      exit();
    },
    [createNewUser, exit]
  );

  return (
    <Dialog open={true} onClose={exit} maxWidth={"sm"} fullWidth>
      <DialogTitle>Thêm Admin</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <FormInput
            id={"name"}
            name={"name"}
            control={control}
            variant={"outlined"}
            fullWidth
            label={"Tên hiển thị"}
            required
          />
          <FormInput
            id={"email"}
            name={"email"}
            control={control}
            variant={"outlined"}
            fullWidth
            label={"Email"}
            helperText={
              "Email được sử dụng để đăng nhập tại màn hình đăng nhập"
            }
            required
          />
          <FormInput
            id={"password"}
            name={"password"}
            control={control}
            variant={"outlined"}
            fullWidth
            label={"Mật khẩu"}
            helperText={
              "Mật khẩu được sử dụng để đăng nhập tại màn hình đăng nhập. Thông tin đăng nhập bao gồm Email và Mật khẩu sẽ được gửi qua email đến admin mới ngay sau khi tài khoản được tạo"
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color={"inherit"} onClick={exit}>
          Bỏ qua
        </Button>
        <LoadingButton
          color={"primary"}
          loading={formState.isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Tạo tài khoản
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
