import {
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import { forwardRef } from "react";

const TextField = styled(
  forwardRef(function TextFieldForwardedRef(
    props: Omit<TextFieldProps, "variant">,
    ref: any
  ) {
    return <MuiTextField ref={ref} variant={"outlined"} {...props} />;
  })
)``;

export default TextField;
