import { useLocation, useNavigate } from "react-router";
import { useCallback } from "react";

export function useExitDialog() {
  const navigate = useNavigate(),
    location = useLocation();

  return useCallback(() => {
    navigate(location.pathname.split("/").slice(0, -1).join("/"), {
      replace: true,
    });
  }, [location, navigate]);
}
