import {
  Box,
  CardContent,
  CardHeader,
  css,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  styled,
  Tab,
  TabProps,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Control,
  FieldPath,
  get,
  set,
  SubmitHandler,
  useForm,
  useFormState,
} from "react-hook-form";
import {
  PublishStatus,
  refetchGetHighlightFeaturesOnShowcaseQuery,
  refetchGetOneHighlightFeatureQuery,
  refetchShowcaseForUpdateQuery,
  ShowcaseCreateInputDto,
  ShowcaseStatus,
  ShowcaseUpdateInputDto,
  useCreateNewImageListOnShowcaseMutation,
  useCreateOneHighlightFeatureMutation,
  useDeleteOneMediaFromImageListMutation,
  useGetHighlightFeaturesOnShowcaseQuery,
  useGetOneHighlightFeatureQuery,
  useInsertOneMediaToImageListMutation,
  useSetBrandOnShowcaseMutation,
  useUpdateOneHighlightFeatureMutation,
} from "../../types/graphql";
import {
  ContentCrudAdapter,
  FormInput,
  HighlightFeature,
  ImageListEditor,
  ImageUploader,
  removeDraftIds,
} from "@hungphongbk/vth-sdk";
import TextField from "../../components/TextField";
import PageCard from "../../components/PageCard";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PlusIcon } from "../../assets/icons";
import { useSnackbar } from "notistack";
import ShowcasePriceInput from "./components/sale-data-input";
import { DevTool } from "@hookform/devtools";
import InvestmentInfo from "./components/investment-info";
import { omitDeep } from "../../utils/object";
import ShowcasePriceDataInput from "./components/showcase-price-data-input";
import { pick } from "lodash";
import ShowcaseWrapperHoc from "./showcase-wrapper-hoc";
import PrjUpdateEditor from "./components/prj-update-editor";
import AdminComment from "./components/admin-comment";
import { yupResolver } from "@hookform/resolvers/yup";
import { showcaseAddValidation } from "./showcase-add-validation";
import { useExitDialog } from "../../utils/router";
import ShowcaseAddPublisher from "./components/showcase-add-publisher";
import BrandSelector from "../../components/brand-selector";
import styles from "./showcase-add.module.scss";

interface ShowcaseForm extends ShowcaseCreateInputDto {
  brandId: string;
}

const StyledTab = styled(Tab)<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    css`
      &,
      &.Mui-selected {
        color: #ff0000;
      }
    `}
`;
function ValidatableTab({
  control,
  fields = [],
  ...props
}: TabProps & {
  control: Control<ShowcaseForm>;
  fields?: FieldPath<ShowcaseForm>[];
}) {
  const { errors } = useFormState({
    control,
    name: fields,
  });
  const haveError = useMemo(() => {
    return fields!.some((f) => Boolean(errors[f]));
  }, [fields, errors]);

  return <StyledTab {...props} error={haveError} />;
}

const ShowcaseAdd = ShowcaseWrapperHoc(function ShowcaseAdd({
  mode,
  slug,
  showcase,
  createShowcase,
  updateShowcase,
}): JSX.Element {
  const form = useForm<ShowcaseForm>({
      defaultValues: {
        status: ShowcaseStatus.Showcase,
        publishStatus: PublishStatus.Draft,
        brandId: null,
      } as unknown as ShowcaseForm,
      resolver: yupResolver(showcaseAddValidation),
    }),
    {
      control,
      formState: { isSubmitting, dirtyFields },
      handleSubmit,
      watch,
      setValue: setFormValue,
      reset,
    } = form;

  const theme = useTheme();

  const exit = useExitDialog();

  const [setBrandOnShowcase] = useSetBrandOnShowcaseMutation();

  const title = watch("name");

  useEffect(() => {
    if (showcase) {
      const showcaseForInput: ShowcaseUpdateInputDto = omitDeep(
        showcase.showcase,
        ["__typename", "author", "slug", "createdAt", "updatedAt", "brand"]
      );
      reset(
        {
          ...showcaseForInput,
          brandId: showcase.showcase.brand.id,
        },
        { keepDirty: false }
      );
    }
  }, [reset, showcase]);
  useEffect(() => {
    if (mode === "edit" && showcase) {
      //@ts-ignore
      setFormValue("authorUid", showcase!.showcase.author.uid, {
        shouldDirty: false,
      });
    }
  }, [mode, setFormValue, showcase]);

  const showcaseStatus = watch("status"),
    isComingSoon = useMemo(
      () => showcaseStatus === ShowcaseStatus.Coming,
      [showcaseStatus]
    );

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState("1");

  useEffect(() => {
    if (!isComingSoon) {
      setValue("2");
    }
  }, [isComingSoon]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const doCreateShowcase: SubmitHandler<ShowcaseForm> = async (values) => {
    // console.log(removeDraftIds(values));
    const { brandId, ...rest } = values;
    const { data } = await createShowcase({
      variables: {
        input: removeDraftIds(rest),
      },
    });
    await setBrandOnShowcase({
      variables: {
        brandId,
        showcaseId: data!.createOneShowcase.id,
      },
    });
    await enqueueSnackbar(
      <>
        Showcase{" "}
        <strong>{data!.createOneShowcase.name} được tạo thành công!</strong>
      </>,
      { variant: "success" }
    );
  };
  const doUpdateShowcase: SubmitHandler<ShowcaseForm> = async (values) => {
    const { brandId, ...payload } = Object.keys(dirtyFields).reduce(
      (obj, key) => {
        const newObj = { ...obj };
        set(newObj, key, get(values, key));
        return newObj;
      },
      pick(values, [
        "expectedSaleAt",
        "expectedSaleEndAt",
      ]) as unknown as ShowcaseForm
    );
    await updateShowcase({ variables: { slug: slug!, input: payload } });
    if (brandId)
      await setBrandOnShowcase({
        variables: { brandId, showcaseId: values.id! },
      });
    enqueueSnackbar(<>Showcase được cập nhật thành công!</>, {
      variant: "success",
    });
  };
  const onSubmit = async (values: ShowcaseForm) => {
    if (mode === "add") await doCreateShowcase(values);
    else await doUpdateShowcase(values);
    exit();
  };

  return (
    <Dialog open={true} maxWidth={"xl"} fullWidth onClose={exit}>
      <DialogTitle>
        {mode === "add" ? "Tạo showcase mới" : `Chỉnh sửa "${title}"`}
        {mode === "edit" && showcase?.showcase?.slug && (
          <AdminComment showcaseSlug={showcase.showcase.slug} />
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={8} lg={9}>
            <Stack gap={2}>
              <FormInput
                name={"name"}
                control={control}
                component={TextField}
                label={"Tên dự án"}
                fullWidth
              />
              <FormInput
                name={"description"}
                control={control}
                component={TextField}
                label={"Chú thích sản phẩm"}
                fullWidth
                multiline
                minRows={4}
              />
              <ShowcasePriceDataInput control={control} setValue={setValue} />
              <PageCard>
                <CardContent>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        {isComingSoon && (
                          <ValidatableTab
                            label={"Mở bán & Đầu tư"}
                            value={"__price"}
                            control={control}
                            fields={["inventory"]}
                          />
                        )}
                        <ValidatableTab
                          label="Tính năng nổi bật"
                          value="2"
                          control={control}
                        />
                        <ValidatableTab
                          label="Ảnh/Video"
                          value="3"
                          control={control}
                        />
                        <ValidatableTab
                          label="Cập nhật sản phẩm"
                          value="4"
                          control={control}
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="2" sx={{ px: 0 }}>
                      <ContentCrudAdapter
                        mode={mode}
                        hooks={{
                          getAll: [
                            useGetHighlightFeaturesOnShowcaseQuery,
                            { variables: { slug } },
                          ],
                          getAllPath: "showcase.highlightFeatures",
                          refetchAll:
                            refetchGetHighlightFeaturesOnShowcaseQuery({
                              slug,
                            }),
                          getOneFn: useGetOneHighlightFeatureQuery,
                          refetchOneFn: refetchGetOneHighlightFeatureQuery,
                          create: [
                            useCreateOneHighlightFeatureMutation,
                            { variables: { slug } },
                          ],
                          update: [
                            useUpdateOneHighlightFeatureMutation,
                            { variables: { slug } },
                          ],
                        }}
                        name={"highlightFeatures"}
                        control={control}
                        ItemComponent={(itemProps) => (
                          <Grid item xs={4}>
                            <HighlightFeature
                              {...itemProps}
                              DialogProps={{ maxWidth: "sm", fullWidth: true }}
                            />
                          </Grid>
                        )}
                        ListComponent={Grid}
                        ListComponentProps={{ container: true, spacing: 2 }}
                        options={{ deletable: true }}
                      />
                    </TabPanel>
                    <TabPanel value="3">
                      <ImageListEditor
                        control={control}
                        mode={mode}
                        name={"imageLists"}
                        ListComponentProps={{ className: styles.ImageList }}
                        ImageUploaderProps={{
                          ...theme.components!.ImageListEditor!.defaultProps!
                            .ImageUploaderProps,
                          className: styles.ImageListItem,
                        }}
                        hooks={{
                          refetchQuery: refetchShowcaseForUpdateQuery({ slug }),
                          createNewMutation:
                            useCreateNewImageListOnShowcaseMutation,
                          createNewMutationArgs: {
                            variables: { slug },
                          },
                          addMutation: useInsertOneMediaToImageListMutation,
                          deleteMutation:
                            useDeleteOneMediaFromImageListMutation,
                        }}
                      />
                    </TabPanel>
                    <TabPanel value="4">
                      <PrjUpdateEditor
                        mode={mode}
                        slug={slug}
                        control={control}
                      />
                    </TabPanel>
                    {isComingSoon && [
                      <TabPanel value={"__price"} sx={{ px: 0 }}>
                        <ShowcasePriceInput
                          control={control}
                          setValue={setFormValue}
                        />
                        <InvestmentInfo control={control} />
                      </TabPanel>,
                    ]}
                  </TabContext>
                </CardContent>
              </PageCard>
            </Stack>
          </Grid>
          <Grid item xs={4} lg={3}>
            <Stack gap={2}>
              <FormInput
                name={"brandId"}
                control={control}
                component={BrandSelector}
              />
              <ShowcaseAddPublisher
                control={control}
                isSubmitting={isSubmitting}
                onSubmit={handleSubmit(onSubmit)}
                mode={mode as "add"}
              />
              <PageCard>
                <CardHeader title={"Media"} />
                <CardContent>
                  <FormInput
                    name={"image"}
                    control={control}
                    component={ImageUploader}
                  >
                    <Stack
                      direction={"column"}
                      alignItems={"center"}
                      id={"showcase-thumbnail-upload"}
                    >
                      <PlusIcon
                        sx={{ color: "black", height: 36, width: 36, mb: 0.5 }}
                      />
                      <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                        Banner dự án
                      </Typography>
                      <Typography>JPEG, JPG - 1000x1000px</Typography>
                      <Typography>Tối đa 1MB</Typography>
                    </Stack>
                  </FormInput>
                </CardContent>
              </PageCard>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </Dialog>
  );
});

export default ShowcaseAdd;
