import { Grid, InputAdornment, Typography } from "@mui/material";
import { Control, useWatch } from "react-hook-form";
import {
  ShowcaseCreateInputDto,
  useCalculateInvestorStatLazyQuery,
} from "../../../types/graphql";
import { FormInput, SimpleTableRoot } from "@hungphongbk/vth-sdk";
import TextField from "../../../components/TextField";
import { useEffect, useMemo } from "react";

type ShowcaseInvestmentInfoProps<T> = {
  control: Control<T, Object>;
};
export default function InvestmentInfo<T = ShowcaseCreateInputDto>({
  control,
}: ShowcaseInvestmentInfoProps<T>): JSX.Element;
export default function InvestmentInfo({
  control,
}: ShowcaseInvestmentInfoProps<ShowcaseCreateInputDto>): JSX.Element {
  const expectedQuantity = useWatch({ control, name: "expectedQuantity" }),
    expectedSalePrice = useWatch({ control, name: "expectedSalePrice" }),
    expectedSaleAt = useWatch({ control, name: "expectedSaleAt" }),
    expectedSaleEndAt = useWatch({ control, name: "expectedSaleEndAt" }),
    inventory = useWatch({ control, name: "inventory" });
  const [fetchCalculate, { data }] = useCalculateInvestorStatLazyQuery({});

  const isCalculatable = useMemo(() => {
    return (
      expectedQuantity &&
      expectedSalePrice &&
      expectedSaleAt &&
      expectedSaleEndAt &&
      inventory?.expectedGrowthRate &&
      inventory?.adCostRate &&
      inventory?.capitalizationRate &&
      inventory?.operatingCostRate &&
      inventory?.revolvingInterval
    );
  }, [
    expectedQuantity,
    expectedSaleAt,
    expectedSaleEndAt,
    expectedSalePrice,
    inventory,
  ]);

  useEffect(() => {
    if (isCalculatable) {
      // noinspection JSIgnoredPromiseFromCall
      fetchCalculate({
        variables: {
          input: {
            expectedQuantity,
            expectedSalePrice,
            expectedSaleAt,
            expectedSaleEndAt,
            inventory,
          },
        },
      });
    }
  }, [isCalculatable, fetchCalculate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormInput
          name={"inventory.capitalizationRate"}
          control={control}
          component={TextField}
          label={"Tỉ lệ vốn hoá"}
          size={"small"}
          fullWidth
          type={"number"}
          InputProps={{
            endAdornment: <InputAdornment position={"end"}>%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInput
          name={"inventory.adCostRate"}
          control={control}
          component={TextField}
          label={"Tỉ lệ chi phí QC"}
          size={"small"}
          fullWidth
          type={"number"}
          InputProps={{
            endAdornment: <InputAdornment position={"end"}>%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInput
          name={"inventory.operatingCostRate"}
          control={control}
          component={TextField}
          label={"Chi phí vận hành"}
          size={"small"}
          fullWidth
          type={"number"}
          InputProps={{
            endAdornment: <InputAdornment position={"end"}>%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInput
          name={"inventory.revolvingInterval"}
          control={control}
          component={TextField}
          label={"Vòng quay vốn an toàn"}
          size={"small"}
          fullWidth
          type={"number"}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>ngày</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInput
          name={"inventory.expectedGrowthRate"}
          control={control}
          component={TextField}
          label={"Tốc độ kì vọng"}
          size={"small"}
          fullWidth
          type={"number"}
          InputProps={{
            endAdornment: <InputAdornment position={"end"}>%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h6"} sx={{ mt: 2, mb: 1, textAlign: "center" }}>
          Bảng thống kê sơ lược
        </Typography>
        {data?.calculateInventoryStat && (
          <SimpleTableRoot>
            <table>
              <tbody>
                <tr>
                  <td className={"uneditable"}>Thời gian chạy Pre-order</td>
                  <td>{data.calculateInventoryStat.campaignDuration}</td>
                </tr>
                <tr>
                  <td className={"uneditable"}>
                    Doanh thu từ chiến dịch Pre-order
                  </td>
                  <td>{data.calculateInventoryStat.totalRevenue}</td>
                </tr>
                <tr>
                  <td className={"uneditable"}>
                    Tốc độ tăng trưởng mỗi tháng kì vọng (30 ngày)
                  </td>
                  <td>{data.calculateInventoryStat.growthRate}</td>
                </tr>
                <tr>
                  <td className={"uneditable"}>Doanh thu dự kiến 1 năm</td>
                  <td>{data.calculateInventoryStat.firstYearRevenue}</td>
                </tr>
              </tbody>
            </table>
          </SimpleTableRoot>
        )}
      </Grid>
    </Grid>
  );
}
