import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import introspection from "./introspection.json";
import { setContext } from "@apollo/client/link/context";
import { getPersistAuth } from "../service/firebase-auth";
import { relayStylePagination } from "@hungphongbk/vth-sdk";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { token } = await getPersistAuth();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-vth-from": "admin",
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    ...introspection,
    typePolicies: {
      Showcase: { keyFields: ["slug"] },
      PreorderDto: { keyFields: ["id"] },
      User: { keyFields: ["email"] },
      Query: {
        fields: {
          showcases: relayStylePagination([
            "@connection",
            ["filter", "size", "after"],
          ]),
          preorderDtos: relayStylePagination([
            "@connection",
            ["filter", "size", "after"],
          ]),
          brandDtos: relayStylePagination([
            "@connection",
            ["filter", "size", "after"],
          ]),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

// if (process.env.NODE_ENV === "development") {
// @ts-ignore
window.__APOLLO_CLIENT__ = apolloClient;
// }

export { apolloClient };
