import styled from "styled-email-components";
import { mailCssBase, MailTable } from "./css";

type MailProps = {
  logo: any;
};

const MailHeaderTable = styled(MailTable)`
    width: 100%;
  `,
  Tr = styled.tr`
    ${mailCssBase}
  `,
  Td = styled.td`
    ${mailCssBase}
  `;

export default function MailHeaderNew(props: MailProps): JSX.Element {
  return (
    <MailHeaderTable>
      <tbody>
        <Tr>
          <Td>
            <img src={props.logo} alt={"Vaithuhay Showcase"} width={"100%"} />
          </Td>
        </Tr>
      </tbody>
    </MailHeaderTable>
  );
}
