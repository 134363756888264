import { MailTable } from "../mail/css";
import { serialize } from "../utils/serialize";
import MailLogo from "../../../../assets/mail/header-preorder-idea.mail.png";
import MailWrapperNew from "../mail/MailWrapperNew";

export const PreorderNotifyIdeaTemplate = serialize(
  <MailWrapperNew logo={MailLogo}>
    <MailTable></MailTable>
  </MailWrapperNew>
);
