import PageTitle from "../../components/PageTitle";
import { useRelayCursorPagination } from "../../utils/use-relay-cursor-pagination";
import { BrandListDocument, useBrandListQuery } from "../../types/graphql";
import { useEffect } from "react";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { Box, styled } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import EditIcon from "@mui/icons-material/Edit";
import { Outlet, useNavigate } from "react-router";
import { apolloClient } from "../../api";

const StyledDataGrid = styled(DataGrid)`
  .avatar-cell {
    padding-right: 0;
  }
`;

type BrandManagementProps = {};
export default function BrandManagement(
  props: BrandManagementProps
): JSX.Element {
  const navigate = useNavigate();
  const {
    page,
    size,
    after,
    handlePageChange,
    handlePageSizeChange,
    setPageInfo,
  } = useRelayCursorPagination();
  const { data, loading: fetching } = useBrandListQuery({
    variables: {
      size,
      after,
    },
  });
  useEffect(() => {
    if (data?.brandDtos.pageInfo) setPageInfo(data.brandDtos.pageInfo);
  }, [data, setPageInfo]);

  const d = apolloClient.readQuery({
    query: BrandListDocument,
    variables: { size },
  });
  console.log(d);

  const columns: GridColumns = [
    {
      field: "logo",
      headerName: "",
      width: 80,
      cellClassName: "avatar-cell",
      renderCell({ value }) {
        return (
          <Box
            sx={{
              height: 70,
              width: 70,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {value ? (
              <img src={value} alt="" />
            ) : (
              <ImageIcon color={"disabled"} />
            )}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Tên thương hiệu",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Tác vụ",
      width: 150,
      getActions: ({ getValue, id }) => [
        <GridActionsCellItem
          key={"__edit"}
          icon={<EditIcon />}
          label={"Edit"}
          onClick={() => navigate(`${id}`)}
        />,
      ],
    },
  ];

  return (
    <>
      <PageTitle>Quản lý thương hiệu</PageTitle>
      <StyledDataGrid
        columns={columns}
        rows={data ? data.brandDtos.edges.map((p) => p.node) : []}
        rowCount={data?.brandDtos.totalCount ?? 0}
        rowsPerPageOptions={[5, 10]}
        autoHeight
        disableSelectionOnClick
        disableVirtualization
        rowHeight={70}
        paginationMode={"server"}
        page={page}
        pageSize={size}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        disableColumnFilter
        disableColumnMenu
        loading={fetching}
      />
      <Outlet />
    </>
  );
}
