import { Navigate, Route, Routes } from "react-router-dom";
import RequireAuth from "../components/require-auth";
import Dashboard from "./Dashboard";
import CommonSettings from "./common-settings/CommonSettings";
import UserManagement from "./user-management/user-management";
import ShowcaseManagement from "./showcase-management/showcase-management";
import ShowcaseAdd from "./showcase-management/showcase-add";
import SignIn from "./sign-in";
import React from "react";
import InvestorSubmits from "./user-management/investor-submits";
import UserList from "./user-management/user-list";
import PreorderManagement from "./preorder-management/preorder-management";
import SettingsMailTemplate from "./settings-management/settings-mail-template";
import MailTemplateEditor from "./settings-management/settings-mail-template/editor";
import AddUser from "./user-management/add-user";
import BrandManagement from "./brand-management/brand-management";
import BrandDetail from "./brand-management/brand-detail";

type AppRoutesProps = unknown;
export default function AppRoutes(props: AppRoutesProps): JSX.Element {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route index element={<CommonSettings />} />
        <Route path="common" element={<CommonSettings />} />
        <Route path="users" element={<UserManagement />}>
          <Route path={""} element={<Navigate replace to={"/users/list"} />} />
          <Route path={"investor-submit"} element={<InvestorSubmits />} />
          <Route path={"list"} element={<UserList />}>
            <Route path={"add-admin"} element={<AddUser />} />
          </Route>
        </Route>
        <Route path="showcases" element={<ShowcaseManagement />}>
          <Route path={":slug"} element={<ShowcaseAdd />} />
        </Route>
        <Route path="brands" element={<BrandManagement />}>
          <Route path={":id"} element={<BrandDetail />} />
        </Route>
        <Route path={"preorders"} element={<PreorderManagement />} />
        <Route
          path={"settings/mail-template"}
          element={<SettingsMailTemplate />}
        >
          <Route path={":id"} element={<MailTemplateEditor />} />
        </Route>
      </Route>
      <Route path={"login"} element={<SignIn />} />
    </Routes>
  );
}
