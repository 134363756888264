import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { NavLink as RouterLink, NavLinkProps } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleIcon from "@mui/icons-material/Article";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import SettingsIcon from "@mui/icons-material/Settings";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";
import HouseIcon from "@mui/icons-material/House";
import { ForwardedRef, forwardRef, useCallback } from "react";
import { useNavigate } from "react-router";
import { signOut } from "../service/firebase-auth";
import styles from "./app-side-menu.module.scss";
import clsx from "clsx";

const NavLink = forwardRef(
  ({ className, ...props }: NavLinkProps, ref: ForwardedRef<any>) => (
    <RouterLink
      ref={ref}
      {...props}
      className={({ isActive }) =>
        clsx(
          className,
          isActive ? styles.MenuListItemButtonActive : styles.MenuListItemButton
        )
      }
    />
  )
);

const MenuListItemButton = forwardRef(function MenuListItemButton(
  props: ListItemButtonProps<typeof NavLink>,
  ref: ForwardedRef<any>
): JSX.Element {
  return <ListItemButton ref={ref} component={NavLink} {...props} />;
});

type AppSideMenuProps = {};
export default function AppSideMenu(props: AppSideMenuProps): JSX.Element {
  const navigate = useNavigate();
  const handleSignout = useCallback(async () => {
    await signOut();
    navigate("/login");
  }, [navigate]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <List
        sx={{ flex: 1, px: 1, "& .MuiListItemIcon-root": { minWidth: "36px" } }}
      >
        <MenuListItemButton to={"/common"}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"Thiết lập chung"} />
        </MenuListItemButton>
        <MenuListItemButton to={"/showcases"}>
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary={"Showcases"} />
        </MenuListItemButton>
        <List component={"div"} disablePadding>
          <MenuListItemButton to={"/preorders"} sx={{ pl: 4 }}>
            <ListItemIcon>
              <BookmarksIcon />
            </ListItemIcon>
            <ListItemText primary={"Đặt trước"} />
          </MenuListItemButton>
          <MenuListItemButton to={"/brands"} sx={{ pl: 4 }}>
            <ListItemIcon>
              <HouseIcon />
            </ListItemIcon>
            <ListItemText primary={"Thương hiệu"} />
          </MenuListItemButton>
        </List>
        <MenuListItemButton to={"/users"}>
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          <ListItemText primary={"Người dùng"} />
        </MenuListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={"Cài đặt"} />
        </ListItemButton>
        <List component={"div"} disablePadding>
          <MenuListItemButton to={"/settings/mail-template"} sx={{ pl: 4 }}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary={"Email"} />
          </MenuListItemButton>
        </List>
      </List>
      <List sx={{ "& .MuiListItemIcon-root": { minWidth: "36px" } }}>
        <ListItemButton onClick={handleSignout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Đăng xuất"} />
        </ListItemButton>
      </List>
    </Box>
  );
}
