import { useBrandListQuery } from "../types/graphql";
import { Autocomplete, Box } from "@mui/material";
import TextField from "./TextField";
import { useMemo } from "react";

type BrandSelectorProps = {
  value: string | undefined;
  onChange: (value: any) => void;
};
export default function BrandSelector(props: BrandSelectorProps): JSX.Element {
  const { data, loading: fetching } = useBrandListQuery({
    variables: {
      size: 100,
    },
  });
  const value = useMemo(
    () => data?.brandDtos.edges.find((e) => e.node.id === props.value)?.node,
    [data?.brandDtos.edges, props.value]
  );
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: "100%" }}
      options={data?.brandDtos.edges.map((e) => e.node) ?? []}
      autoHighlight
      getOptionLabel={(option) => option.name}
      value={value || null}
      onChange={(_, newValue) => props.onChange(newValue?.id ?? null)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.logo && (
            <img
              loading="lazy"
              width="20"
              src={option.logo}
              alt={`thuong hieu ${option.name}`}
            />
          )}
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Chọn một thương hiệu"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
