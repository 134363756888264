import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./styles/theme";
import {
  Dialog,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { PromptProvider, VthThemeProvider } from "@hungphongbk/vth-sdk";
import TextField from "./components/TextField";
import { UploadService } from "./service";
import { SnackbarProvider } from "notistack";
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import AppRoutes from "./pages/app-routes";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api";
import viVN from "date-fns/locale/vi";

const globalStyles = (
  <GlobalStyles styles={{ body: { letterSpacing: "-0.012em" } }} />
);

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <VthThemeProvider
            config={{
              components: {
                Dialog: Dialog,
                TextField: TextField,
                MultilineTextField: TextField,
              },
              services: {
                uploadService: UploadService.upload,
              },
            }}
          >
            <SnackbarProvider maxSnack={3}>
              <PromptProvider>
                <LocalizationProvider dateAdapter={DateAdapter} locale={viVN}>
                  <CssBaseline />
                  {globalStyles}
                  <AppRoutes />
                </LocalizationProvider>
              </PromptProvider>
            </SnackbarProvider>
          </VthThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
}

export default App;
