import { serialize } from "../utils/serialize";
import MailWrapper from "../mail/MailWrapper";
import { hbsEscaped, MailTable, MailTd } from "../mail/css";

export const InvestorRegNotifyTemplate = serialize(
  <MailWrapper>
    <MailTable>
      <tbody>
        <tr>
          <MailTd>
            Xin cám ơn <strong>{hbsEscaped.var("name")}</strong> đã đăng ký trở
            thành nhà đầu tư của Vaithuhay Showcase. Chúng tôi đang trong quá
            trình phê duyệt đơn đăng ký của bạn, và sẽ có thông báo đến cho bạn
            khi có kết quả trong thời gian sớm nhất có thể!
          </MailTd>
        </tr>
      </tbody>
    </MailTable>
  </MailWrapper>
);
