import { PreorderNotifyTemplate } from "./preorder-notify";
import { deserialize } from "../utils/serialize";
import MailWrapper from "../mail/MailWrapper";
import { InvestorPromoteNotifyTemplate } from "./investor-promote-notify";
import { InvestorRegNotifyTemplate } from "./investor-reg-notify";
import { NewAdminNotifyTemplate } from "./new-admin-notify";
import { PreorderNotifyComingSoonTemplate } from "./preorder-notify-comingsoon";
import MailWrapperNew from "../mail/MailWrapperNew";
import { PreorderNotifyShowcaseTemplate } from "./preorder-notify-showcase";
import { PreorderNotifyIdeaTemplate } from "./preorder-notify-idea";

export default {
  "preorder-notify": PreorderNotifyTemplate,
  "investor-promote-notify": InvestorPromoteNotifyTemplate,
  "investor-reg-notify": InvestorRegNotifyTemplate,
  "new-admin-notify": NewAdminNotifyTemplate,
  "preorder-notify-comingsoon": PreorderNotifyComingSoonTemplate,
  "preorder-notify-showcase": PreorderNotifyShowcaseTemplate,
  "preorder-notify-idea": PreorderNotifyIdeaTemplate,
} as const;

export const mailDeserialize = (serialized: string) =>
  deserialize(serialized, {
    // @ts-ignore
    components: {
      [MailWrapper.displayName]: MailWrapper,
      [MailWrapperNew.displayName]: MailWrapperNew,
    },
  });
