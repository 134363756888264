import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router";
import { useRelayCursorPagination } from "../../utils/use-relay-cursor-pagination";
import {
  PreorderDtoFilter,
  PreorderDtoSort,
  Showcase,
  usePreordersQuery,
} from "../../types/graphql";
import { useEffect, useState } from "react";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, Stack, Typography } from "@mui/material";
import Thumbnail from "../showcase-management/components/thumbnail";
import ShowcaseStatusChip from "../../components/showcase-status-chip";
import { useGqlGridSortModel } from "../../utils/use-gql-grid-sort-model";

type PreorderManagementProps = {};
export default function PreorderManagement(
  props: PreorderManagementProps
): JSX.Element {
  const navigate = useNavigate(),
    [filter, setFilter] = useState<PreorderDtoFilter>({});

  // pagination section
  const {
    page,
    size,
    after,
    handlePageChange,
    handlePageSizeChange,
    setPageInfo,
  } = useRelayCursorPagination();

  const { sort, handleSortModelChange } = useGqlGridSortModel<
    PreorderDtoSort[]
  >([{ sort: "desc", field: "createdAt" }]);

  const { data, loading: fetching } = usePreordersQuery({
    variables: {
      size,
      filter,
      after,
      sort,
    },
  });

  useEffect(() => {
    if (data?.preorderDtos.pageInfo) setPageInfo(data.preorderDtos.pageInfo);
  }, [data, setPageInfo]);

  const columns: GridColumns = [
    {
      field: "author",
      headerName: "Người đặt",
      flex: 1,
      renderCell({ value }) {
        return (
          <Stack gap={0.5}>
            <Typography variant={"body1"}>{(value as any).name}</Typography>
            <Typography variant={"body2"}>{(value as any).email}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "showcase",
      headerName: "Sản phẩm",
      flex: 1.5,
      renderCell({ value, getValue, id }) {
        const showcase = value as Showcase;
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              "& .MuiTypography-root": { fontSize: ".9em" },
            }}
          >
            <Thumbnail
              size={50}
              sx={{ flexBasis: "50px", flexGrow: 0, flexShrink: 0 }}
              src={showcase.image}
            />
            <Box>
              <Typography sx={{ fontWeight: 700 }}>{showcase.name}</Typography>
              <Typography>
                <ShowcaseStatusChip value={showcase.status} />
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Ngày",
      width: 200,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
    },
  ];

  return (
    <>
      <PageTitle>Quản lý đặt trước</PageTitle>
      <DataGrid
        columns={columns}
        rows={data ? data.preorderDtos.edges.map((p) => p.node) : []}
        rowCount={data?.preorderDtos.totalCount ?? 0}
        rowsPerPageOptions={[5, 10]}
        autoHeight
        disableSelectionOnClick
        disableVirtualization
        rowHeight={70}
        paginationMode={"server"}
        page={page}
        pageSize={size}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        disableColumnFilter
        disableColumnMenu
        loading={fetching}
        sortingMode={"server"}
        onSortModelChange={handleSortModelChange}
      />
    </>
  );
}
