import { MailTable } from "../mail/css";
import { serialize } from "../utils/serialize";
import MailLogo from "../../../../assets/mail/header-preorder-comingsoon.mail.png";
import MailWrapperNew from "../mail/MailWrapperNew";

export const PreorderNotifyComingSoonTemplate = serialize(
  <MailWrapperNew logo={MailLogo}>
    <MailTable></MailTable>
  </MailWrapperNew>
);
