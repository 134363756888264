import {
  Control,
  UseFormSetValue,
  useFormState,
  useWatch,
} from "react-hook-form";
import { ShowcaseCreateInputDto } from "../../../types/graphql";
import { useCallback } from "react";
import {
  FormHelperText,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { FormInput, SimpleTableRoot } from "@hungphongbk/vth-sdk";
import { VndAdornment } from "../../../components/adornments";

type ShowcasePriceDataInputProps<T> = {
  control: Control<T, any>;
  setValue: UseFormSetValue<T>;
};
export default function ShowcasePriceDataInput<T = ShowcaseCreateInputDto>({
  control,
  setValue,
}: ShowcasePriceDataInputProps<T>): JSX.Element;
export default function ShowcasePriceDataInput({
  control,
  setValue,
}: ShowcasePriceDataInputProps<ShowcaseCreateInputDto>): JSX.Element {
  const watchPrice = useWatch({ control, name: "expectedSalePrice" }),
    { errors } = useFormState({ control });

  const getPercentage = useCallback(
    (label: any) => {
      if (!watchPrice?.regular) return "-";
      if (!watchPrice?.[label]) return "-";
      return (
        ((watchPrice[label] * 100.0) / watchPrice.regular).toFixed(2) + "%"
      );
    },
    [watchPrice]
  );
  return (
    <Stack gap={1}>
      <Typography variant={"h6"}>Bảng giá</Typography>
      {errors.expectedSalePrice &&
        Object.values(errors.expectedSalePrice).map((err) => (
          <FormHelperText error>
            <strong>Lỗi: </strong>
            {err.message}
          </FormHelperText>
        ))}
      <SimpleTableRoot sx={{ mb: 3 }} error={errors.expectedSalePrice}>
        <table>
          <thead>
            <tr>
              <th>Loại</th>
              <th colSpan={2}>Giá</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={"uneditable"}>Niêm yết</td>
              <td colSpan={2}>
                <FormInput
                  id={"showcase-expected-regular"}
                  name={"expectedSalePrice.regular"}
                  control={control}
                  component={SimpleTableRoot.TextEditor}
                  type={"number"}
                  endAdornment={
                    <InputAdornment position={"end"}>
                      {VndAdornment}
                    </InputAdornment>
                  }
                />
              </td>
            </tr>
            <tr>
              <td className={"uneditable"}>Tiên phong</td>
              <td>
                <FormInput
                  id={"showcase-expected-pioneer"}
                  name={"expectedSalePrice.pioneer"}
                  control={control}
                  component={SimpleTableRoot.TextEditor}
                  type={"number"}
                  endAdornment={
                    <InputAdornment position={"end"}>
                      {VndAdornment}
                    </InputAdornment>
                  }
                />
              </td>
              <td className="uneditable">{getPercentage("pioneer")}</td>
            </tr>
            <tr>
              <td className={"uneditable"}>Khuyến mãi</td>
              <td>
                <FormInput
                  id={"showcase-expected-promo"}
                  name={"expectedSalePrice.promo"}
                  control={control}
                  component={SimpleTableRoot.TextEditor}
                  type={"number"}
                  endAdornment={
                    <InputAdornment position={"end"}>
                      {VndAdornment}
                    </InputAdornment>
                  }
                />
              </td>
              <td className="uneditable">{getPercentage("promo")}</td>
            </tr>
            <tr>
              <td className={"uneditable"}>Đặt trước</td>
              <td>
                <FormInput
                  id={"showcase-expected-preorder"}
                  name={"expectedSalePrice.preorder"}
                  control={control}
                  component={SimpleTableRoot.TextEditor}
                  type={"number"}
                  endAdornment={
                    <InputAdornment position={"end"}>
                      {VndAdornment}
                    </InputAdornment>
                  }
                />
              </td>
              <td className="uneditable">{getPercentage("preorder")}</td>
            </tr>
          </tbody>
        </table>
      </SimpleTableRoot>
    </Stack>
  );
}
