import { serialize } from "../utils/serialize";
import MailWrapper from "../mail/MailWrapper";
import { hbsEscaped, MailH2, MailP, MailTable, MailTd } from "../mail/css";

export const NewAdminNotifyTemplate = serialize(
  <MailWrapper>
    <MailTable>
      <tbody>
        <tr>
          <MailTd>
            <MailH2>Bạn đã trở thành admin của Vaithuhay Showcase</MailH2>
            <MailP>Thông tin đăng nhập của bạn là</MailP>
          </MailTd>
        </tr>
      </tbody>
    </MailTable>
    <MailTable>
      <tbody>
        <tr>
          <MailTd>
            <span style={{ color: "#888" }}>Email</span>
          </MailTd>
          <MailTd>
            <span style={{ color: "#888" }}>Mật khẩu</span>
          </MailTd>
        </tr>
        <tr>
          <MailTd>{hbsEscaped.var("email")}</MailTd>
          <MailTd>{hbsEscaped.var("password")}</MailTd>
        </tr>
      </tbody>
    </MailTable>
  </MailWrapper>
);
