import { PropsWithChildren } from "react";
import styled from "styled-email-components";
import { mailCssBase, MailTable } from "./css";
import MailHeader from "./MailHeader";

const Div = styled.div`
    ${mailCssBase}
    font-size: 14px;
    line-height: 1.5;
    color: #202124;
    background-color: #fff;
    margin: 0;
  `,
  Table = styled(MailTable)`
    border-spacing: 0;
    border-collapse: collapse;
    max-width: 544px;
    margin-right: auto;
    margin-left: auto;
  `,
  Tr = styled.tr`
    ${mailCssBase}
  `,
  Td = styled.td`
    ${mailCssBase}
    padding:16px;
  `,
  Center = styled.center`
    ${mailCssBase}
  `;

export default function MailWrapper(
  props: PropsWithChildren<{ logo?: any }>
): JSX.Element {
  return (
    <Div>
      <Table>
        <tbody>
          <Tr>
            <Td>
              <Center>
                <MailHeader logo={props.logo} />
                {props.children}
              </Center>
            </Td>
          </Tr>
        </tbody>
      </Table>
    </Div>
  );
}
MailWrapper.displayName = "MailWrapper";
// MailWrapper.components = [Div, Table, Tr, Td, Center];
